
















































import { Component, Watch } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import { searchAggregatesByRelation } from '../store/rest.service';
import { ResourceSearchResult, GeneralSearchCriteria, Aggregate, TotalsMeta } from '../store/models';
import { EmptyGeneralSearchCriteria } from '../store/models-empty';
import ErrorComponent from '../components/ErrorComponent.vue';
import SpinnerComponent from '../components/SpinnerComponent.vue';
import { getPagingState, handleError, setPagingState } from '../lib/shared';
import * as _ from 'lodash';

@Component({
  components: {
    ErrorComponent,
    SpinnerComponent,
  },
})
export default class CommunitiesVue extends BaseComponent {

  protected baseRoute: string = '/communities';

  private searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
  private pagination: any = { sortBy: 'common.displayName', descending: false, page: 1, rowsPerPage: 10 };
  private rowsPerPage: number[] = [10, 25, 100];
  private searchTerms: string = '';

  private communities: ResourceSearchResult|null = null;
  private communitiesLoading: boolean = false;

  private arrErrors: Error[] = [];

  private bInitialLoad: boolean = true;
  private bInitialLoadWatch: boolean = true;
  private bPagingStateSet: boolean = false;

  private headers = [
    { text: 'Community Name', align: 'left', sortable: true, value: 'facetDetail.displayName', class: 'grid-header' },
    { text: 'Direct Indigenous Spend', align: 'right', sortable: true, value: 'totals.expenses.diverseSpend.totalDirectIndigenousSpend', class: 'grid-header' },
    { text: 'Project Benefits', align: 'right', sortable: true, value: 'totals.expenses.expenseTotals.totalIndigenousProjectContributions', class: 'grid-header' },
    { text: 'Community Contribution', align: 'right', sortable: true, value: 'totals.expenses.expenseTotals.totalIndigenousCommunityContributions', class: 'grid-header' },
    { text: 'Community Employment', align: 'right', sortable: true, value: 'totals.expenses.employment.totalIndigenousEmploymentValue', class: 'grid-header' },
  ];

  get communityResults(): Aggregate[] {
    if (!this.communities) {
      return [];
    }
    return (this.communities.searchResults.results as Aggregate[]).filter((result) => {
      const totals = (result.totals as TotalsMeta);
      const diverseSpend = totals.expenses ? totals.expenses.diverseSpend ? totals.expenses.diverseSpend.totalDirectIndigenousSpend : 0 : 0;
      const projectContributions = totals.expenses ? totals.expenses.expenseTotals ? totals.expenses.expenseTotals.totalIndigenousProjectContributions : 0 : 0;
      const communityContributions = totals.expenses ? totals.expenses.expenseTotals ? totals.expenses.expenseTotals.totalIndigenousCommunityContributions : 0 : 0;
      const employmentValue = totals.expenses ? totals.expenses.employment ? totals.expenses.employment.totalIndigenousEmploymentValue : 0 : 0;

      if (diverseSpend || projectContributions || communityContributions || employmentValue) {
        return true;
      }
      return false;
    });
  }

  protected changeCurrentOrg() {
    this.getCommunities();
  }

  protected mounted() {
    // Reload the paging state from session storage.
    const pagingState: any = getPagingState(this.$store, this.$route.path);
    if (pagingState) {
      this.pagination = pagingState;
    } else {
      this.bPagingStateSet = true;
    }
    this.getCommunities();
  }

  private getHeaders() {
    // Filter out the Community Employment column if the feature is disabled.
    return this.isFeatureEnabled(this.features.communityEmploymentTables) ? this.headers : this.headers.filter((header) => header.text !== 'Community Employment');
  }

  private handleError(error) {
    handleError(this.arrErrors, error);
  }

  @Watch('pagination', { deep: true })
  private onPaginationChanged(val: any, oldVal: any) {
    setPagingState(this.$store, this.$route.path, this.pagination);
  }

  private navCommunity(id: string) {
    this.$router.push({ path: '/communities/id/' + id });
  }

  private getCommunities() {
    this.communitiesLoading = true;
    searchAggregatesByRelation(this.searchCriteria, 'relatedic', this.currentOrg)
    .then((response) => {
      this.communities = response;
    }).catch((error) => {
      this.arrErrors.push(error);
    }).finally(() => {
      this.communitiesLoading = false;
      this.bInitialLoad = false;
    });
  }
}
